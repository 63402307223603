<template>
    <div>
        <div class="form-group mb-2 mt-2">
            <label class="form-label" for="username">{{ usernameLabel }}</label>
            <input v-on:keyup.enter="authenticate" :disabled="isAuthenticated" type="text" name="username" v-model.trim="trimmedUsername" id="username" class="form-control" :class="{ 'is-invalid' : errors?.username }" autocomplete="username" required :placeholder="usernameLabel"/>
            <span class="invalid-feedback mt-0" v-if="errors?.username">{{ errors.username }}</span>
        </div>

        <div class="form-group mb-2 mt-2">
            <label for="password"  class="form-label">Password</label>
            <div class="position-relative">
                <input v-on:keyup.enter="authenticate" :type="showPassword ? 'text' : 'password'" class="form-control" id="password" v-model="password" :class="{ 'is-invalid' : state.errors?.password }" autocomplete="current-password" required placeholder="Password">
                <i class="position-absolute bi top-right" :class="showPassword ? 'bi-eye' : 'bi-eye-slash'" @click="showPassword = !showPassword"></i>
            </div>
            <span class="invalid-feedback mt-0" v-if="state.errors?.password">{{ state.errors?.password }}</span>
        </div>

        <ErrorComponent :errors="errors" :capsLockOn="capsLockOn" :key="state.updated" :exclude="['username', 'password']"/> 

        <div class="d-flex justify-content-between align-items-center my-2" v-if="!isAuthenticated">
            <a type="button" :disabled="isAuthenticated" @click="showReset">Reset Password</a>
            <a type="button" v-if="allowSignup" :disabled="isAuthenticated"  @click="showSignup">
                Create User
            </a>
        </div>
        <div class="form-group mb-2 mt-2">
            <button type="button" :disabled="isAuthenticated" class="btn btn-o365-login" @click="authenticate">{{ isAuthenticated ? 'Logged in' : actionText }}</button>
        </div> 
    </div>
</template>

<style scoped>
    .btn-show-pass {
        position: absolute;
        top: 5px;
        bottom: 5px;
        right: 10px;
        display: none;
        font-size: 20px;
    }

    .top-right {
        position: absolute;
        display: none;
        font-size: 20px;
        right: 0px;
        top: 53%;
        opacity: .5;
        transform: translate(-50%, -50%);
    }

    .form-group:hover .top-right {
        display: block;
        cursor: pointer;
    }

    .form-floating:not(input.is-invalid):hover .btn-show-pass {
        display: block;
    }
    .form-horizontal{
        font-size:13px;
        font-family: Roboto, sans-serif;
    }
</style>

<script setup lang="ts">
    import { ref, computed, inject, onMounted, onUnmounted } from 'vue'
    import { createRequest } from './shared.js';
    import ErrorComponent from './Errors.vue';

    const setErrors = inject('setErrors') as Function;
    const authenticated = inject('authenticated') as Function;
    const updateState = inject('updateState') as Function;
    const isBusy = inject('isBusy') as Function;

    const props = defineProps({ 
        state: { type: Object, required: true } ,
        name:String,
        title:String,
        description:String,
        actionText:String,
        capsLockOn: { type: Boolean, default: false },
        errors: { type: Object, required: false, default: {}}
    });
    console.log(props.errors);

    onMounted(() => {
        console.log('SqlIDentity mounted');
        if(props.state?.isAuthenticated){
            username.value = props.state.username;
            password.value = '*************';
        }
        allowSignup.value = props.state.authentication.sqlIdentity.allowSignup;
        generateUsernameLabel();
        isBusy(false);
    });

    onUnmounted(()=> {
        console.log("SqlIdentity unmounted");
        isBusy(true);
    });

    const usernameLabel = ref('Email, Username or Mobile No');
    const username = ref('');
    const password = ref('');
    const showPassword = ref(false);
    const allowSignup = ref(false);

    const isAuthenticated = computed(() => props.state?.isAuthenticated);
    const trimmedUsername = computed({
        get() {
            return username.value;
        },
        set(value) {
            username.value = value?.replace(/\s/g, '');
        }
    });

    const trimmedPassword = computed({
        get() {
            return password.value;
        },
        set(value) {
            password.value = value;
            validate();
        }
    });

    function generateUsernameLabel(){
        var types = Object.entries(props.state?.authentication?.sqlIdentity?.userTypes).filter(([k,v]) => v === true);
        var label = '';
        for (const [key, val] of Object.entries(types)) {
            let name = val[0]
            if(label.length > 0){
                if(parseInt(key) == types.length - 1){
                    label += ' or ';
                } else label += ', ';
            }
            label += toUpperFirst(name.replace('sqlLogin', 'username').replace('mobile', 'mobile no'));
        }
        usernameLabel.value = label;
    }

    function toUpperFirst(str) {
        const arr = str.split(' ');
        for (var i = 0; i < arr.length; i++) {
            arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
        }
        return arr.join(" ");
    }

    function validate(){
        console.log(props.errors);
        Object.keys(props.errors).forEach(key => delete props.errors[key]);
        if(!username.value.length){
            props.errors['username'] = 'Username field is required';
        }
        if(!password.value.length){
            props.errors['password'] = 'Password field is required';
        }
        if(props.errors['username'] || props.errors['password']) return false;
        if(props.state?.isAuthenticated) return false;
        return true;
    }

    async function authenticate() {
        try {
            if(!validate()) return;
            isBusy(true);
            const response = await createRequest('/api/login', {
                username: username.value,
                password: password.value
            });
            if(response.ok){
                props.state!.isAuthenticated = true;
                authenticated();
            } else if(response.status == 400){
                const err = await response.json();
                console.log(err);
                if(err.state){
                    updateState(err.state);
                }
                if(err.errors){
                    Object.keys(err.errors).forEach(key=>{
                        props.errors[key] = err.errors[key];
                    });
                }  
                isBusy(false);
                
            } else {
                const text = await response.text();
                // errors.value['error'] = text;
                isBusy(false);
            }            
        } catch(e) {
            console.log(e);
            isBusy(false);
        }
    }

    function showReset(){
        setErrors({});
        updateState({
            action: 'reset',
            currentProvider: '',
            multiFactorState: 0
        });
    }

    function showSignup(){
        setErrors({});
        props.state!.action = 'signup'
    }

</script>